<template>
  <div class="header-ad-main-component">
    <div class="header-wrapper d-flex justify-center">
      <div class="container ma-0 px-4 w-80">
        <!-- <div v-if="$vuetify.breakpoint.mdAndDown" class="d-flex align-center">
            <fa-icon
              @click.stop="showDrawer()"
              style="left: 8; font-size: 28px"
              :icon="['fas', 'bars']"
              class="absolute pointer"
            ></fa-icon>
          </div> -->
        <div class="header-logo pointer" @click="redirect('/home')">
          <img lazy-src="logo" :src="logo" />
        </div>
        <div class="d-none">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            @click="
              item.path
                ? goTo(item.path, item.title)
                : showCommingSoon(item.path)
            "
          >
            <v-list-item-content class="d-flex align-center pointer px-1 pb-1">
              <v-list-item-title
                style="font-size: 14px; color: #27272a"
                class="font-weight-medium"
                v-text="item.title"
              ></v-list-item-title>
              <span class="menu-underline"></span>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div v-if="isLoading">
          <template>
            <div class="history-item py-4 px-2 d-flex justify-center">
              <div class="text-center">
                <v-progress-circular
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </div>
            </div>
          </template>
        </div>

        <div class="header-menu authenticated" v-if="hasAuthUser && !isLoading">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :ripple="false" text>
                <v-icon>mdi-account-circle</v-icon>
                <span class="ml-2">{{ getCurrentUserFirstName }}</span>
                <v-icon class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="`/dashboard/playground`">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="goToLogout">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          class="header-menu unauthenticated"
          v-if="!hasAuthUser && !isLoading"
        >
          <v-btn
            depressed
            text
            class="mr-2"
            style="color: #27272a !important"
            to="/sign-in"
            >Sign In</v-btn
          >
          <v-btn
            color="primary"
            rounded
            to="/signup"
            style="color: #fff !important"
            >Sign Up</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logoImage from "@/assets/logos/pinoybot-logo.png";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    drawer: Boolean,
  },
  data: () => ({
    mainDrawer: true,
    items: [
      {
        path: "",
        title: "Products",
      },
      {
        path: "",
        title: "Solutions",
      },
      {
        path: "",
        title: "What they said",
      },
      {
        path: "",
        title: "Pricing",
      },
      {
        path: "",
        title: "About",
      },
      {
        path: "",
        title: "Contact",
      },
    ],
    menus: 0,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("bots", ["bots", "activeBot"]),

    logo() {
      return logoImage;
    },

    getCurrentUserFirstName() {
      return this.currentUser?.first_name;
    },

    hasAuthUser() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 200);

      return this.currentUser ? true : false;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    redirect(url) {
      this.$router.push(url);
    },
    showDrawer() {
      this.$emit("showDrawer", !this.drawer);
    },
    goTo(route, title) {
      if (route.startsWith("/")) {
        this.$router.push(route);
      }
    },
    goToLogout() {
      this.logout().then(
        () => {
          this.goTo("/home");
        },
        (err) => {
          globalMessageHandler(
            err,
            "Something went wrong. Please try again later."
          );
        }
      );
    },
    showCommingSoon(path) {
      if (!path) {
        globalComingSoonHandler(
          "New features and enhancements coming soon!",
          "Coming Soon"
        );
      }
    },
  },
};
</script>
<style lang="scss">
.menu-list {
  .v-item-group {
    display: flex;
  }
}
.header-ad-main-component {
  position: relative;
  background: #fff;
  .header-wrapper {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    background: #fff;
    .container {
      margin: 0 auto;
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .w-80 {
      width: 70% !important;
    }
  }
}

/* Remove the button ripple effect when clicked */
.v-btn--active,
.v-btn--active .v-btn__content {
  background-color: transparent;
  box-shadow: none;
}

.v-list-item--link {
  color: #27272a !important;
}
.v-list-item--active {
  color: #4285f3 !important;
}
</style>
