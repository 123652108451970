<template>
  <div
    @click.stop="open"
    class="history-item pa-2 mx-2 d-flex justify-center"
    :class="active ? 'current-history' : ''"
  >
    <div class="d-flex align-center w-100">
      <span class="mdi mdi-message-outline mr-3" style="font-size: 20px"></span>
      <span class="item-title">
        <span class="text-subtitle-2">{{ item.title }}</span>
      </span>
      <v-btn @click.stop="showConfirmationModal(item)" icon class="ml-auto"
        ><fa-icon color="red" :icon="['far', 'trash-alt']"
      /></v-btn>

      <ModalConfirmation
        :data="myItem"
        text="Are you sure you want to delete this chat?"
        @onConfirm="removeChatItem"
        v-model="showConfirmation"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalConfirmation from "@/components/modals/ModalConfirmation";

export default {
  props: {
    item: Object,
    active: Boolean,
  },
  components: {
    ModalConfirmation,
  },
  data() {
    return {
      showConfirmation: false,
      myItem: null,
    };
  },

  computed: {
    ...mapGetters("chat", ["userHistory"]),
  },

  methods: {
    ...mapActions("chat", ["deleteUserHistory"]),

    open() {
      this.$emit("open");
    },

    showConfirmationModal(item) {
      this.myItem = item;
      this.showConfirmation = true;
    },

    removeChatItem(item) {
      this.deleteUserHistory(item.id).then(
        (res) => {
          globalMessageHandler("Successfully Deleted.");
          let index = _.findIndex(this.userHistory, { id: item.id });

          if (index >= 0) this.userHistory.splice(index, 1);
        },
        (err) => {
          globalErrorHandler(err, "Error Delete!");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.history-item {
  // border-bottom: 1px solid #000;
  background: #fff;
  border-radius: 0.375rem;
  .item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
.history-item:hover {
  background: #efefef;
}
.history-item.current-history {
  background: #dedede;
}
</style>
